import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data }) => (
    <Layout>
        <SEO title="Files Test" />
        <h1>Testing the gatsby-source-filesystem</h1>
        <table>
            <thead><tr>
                <th>relativePath</th>
                <th>prettySize</th>
                <th>extension</th>
                <th>birthTime</th>
            </tr></thead>
            <tbody>
                {data.allFile.edges.map(({ node }) =>
                    <tr>
                        <td>{node.relativePath}</td>
                        <td>{node.prettySize}</td>
                        <td>{node.extension}</td>
                        <td>{node.birthTime}</td>
                    </tr>
                )}
            </tbody>
        </table>
        <Link to="/">Go to home page</Link>
    </Layout>
)

export const query = graphql`
    query FileQuery {
        allFile {
            edges {
                node {
                    extension
                    prettySize
                    relativePath
                    birthTime
                }
            }
        }
    }
`